<template>
	<div id="quemsomos" v-if="dataReady">

		<div class="page-header container-fluid text-center d-flex align-items-center justify-content-center bg-quem-somos">
			<div class="page-header-content">
				<h1 class="text-line-1 mb10">
					<b>QUEM</b> SOMOS
				</h1>
				<h2 class="text-line-2">
					Conheça o nosso passado e saiba como podemos melhorar o seu futuro.
				</h2>
			</div>
		</div>

		<div class="container text-center">
			<div class="row mb100" v-for="(bloco, index) in pageinfo.bloco" :key="bloco.index" :id="'texto'+index" ref="blocotexto">
				<div class="col-12 col-lg-10 offset-lg-1 bloco-texto mb50">
					<h3 v-html="secondWordsBold(bloco.value.title)"></h3>
					<p v-html="bloco.value.text"></p>
				</div>

				<div :class="'qs_grids qs_grid'+index">
					<div v-for="(foto, num) in bloco.value.photos" :key="foto.num" :class="'qs_foto foto'+num">
						<img :src="cockpitUrl+'/'+foto.path" style="max-width:100%;">
					</div>
				</div>

			</div>
		</div>
		

		<MainFooter />

	</div>
</template>

<script>
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
	name: "QuemSomos",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			cockpitUrl: globals.BASE_URL,
			pageinfo: null,
		};
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = 'Quem Somos';
			this.$parent.metaDescription = 'Conheça o nosso passado e saiba como podemos melhorar o seu futuro.';
		},
		secondWordsBold(content) {
			var text = content.trim().split(" ");
			var first = text.shift();
			return first + " <b>"+text.join(" ")+"</b>";
		},
		animateTxt() {
			this.$refs.blocotexto.forEach((divtexto) => {
				gsap.set(divtexto, {y:300, autoAlpha:0});
				gsap.to(divtexto, 3, {
					y:0,
					autoAlpha:1
				});
			});
		},
	},
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+'singletons/get/quemsomos'+globals.COCKPIT_TOKEN)
			.then(response => {
				this.pageinfo = response.data
				this.dataReady = true
				this.changeMetaData()
			})
	},
	updated: function () {
		this.$nextTick(function () {
			// Code that will run only after the
			// entire view has been re-rendered
			console.log('Updated')
			this.animateTxt()
		})
	},
};
</script>